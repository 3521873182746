import React from 'react';
import { FaqSection } from 'libs/growth-platform-brand-system-v2/src/templates/FaqSection';
import { morphism } from 'morphism';
import { schemaFaqSection } from '../../morphismSchema/templates/schemaFaqSection';

export const FaqComponent = ({ block, id, sectionId, locale }: any) => {
  const _block = morphism(schemaFaqSection, {
    ...block,
    section: sectionId,
    locale,
  });

  return <FaqSection {..._block} />;
};

export default FaqComponent;
