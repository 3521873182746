import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import {
  FaqSectionProps,
  QuestionAnswerProps,
} from 'libs/growth-platform-brand-system-v2/src/templates/FaqSection';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import {
  getGoogleAnalyticsId,
  track,
} from 'libs/growth-platform-brand-system-v2/src/utils/tracking';
import { createSchema, morphism } from 'morphism';
import React from 'react';
import { StructuredText } from '../../components/StructuredText';
import {
  filterIsSelfServeButMobile,
  schemaDatoButton,
} from '../schemaDatoButton';

export const schemaFaqSection = createSchema<FaqSectionProps, any>({
  title: 'faqTitle',
  subtitle: 'faqSubtitle',
  icon: 'icon',
  ctaButton: {
    path: ['ctaButton', 'locale'],
    fn: ({ ctaButton, locale }: any) => {
      if (ctaButton?.[0]) {
        const _propertyValue = filterIsSelfServeButMobile(ctaButton)?.[0];
        const cta = morphism(schemaDatoButton, { ..._propertyValue, locale });
        return (
          <ButtonTracking
            component={ButtonV2}
            {...cta}
            labelTracking={cta.title}
          />
        );
      }
      return [];
    },
  },
  body: {
    path: ['faqQuestionsAnswers', 'section'],
    fn: ({
      faqQuestionsAnswers,
      section,
    }: {
      faqQuestionsAnswers: any[];
      section: any;
    }) => {
      const _faqQuestionsAnswers = faqQuestionsAnswers?.map((elt, index) => ({
        ...elt,
        index,
        section,
      }));
      return morphism(schemafaqQuestionsAnswers, _faqQuestionsAnswers);
    },
  },
  backgroundColor: 'backgroundColor',
  isDark: 'isDark',
});

export const schemafaqQuestionsAnswers = createSchema<
  QuestionAnswerProps & { section?: any; index: any; btnProps?: any },
  any
>({
  question: 'question',
  answer: {
    path: 'answer',
    fn: (propertyValue: any) => {
      return <StructuredText text={propertyValue} />;
    },
  },
  index: 'index',
  onClick: {
    path: ['question'],
    fn: ({ question }: any) => {
      return () =>
        track({
          gtm: {
            event: 'Toggle Interaction',
            properties: {
              componentName: 'FAQ',
              toggleLabel: question,
              gaClientID: getGoogleAnalyticsId(),
            },
          },
        });
    },
  },
});
